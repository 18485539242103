<template>
	<div class="h-100">
		<template v-if="recebimentoExiste">
			<div class="h-100 h-100 d-flex flex-column card-panel bg-white shadow-sm shadow-hover">
				<template v-if="isLoading">
					<BaseLoading />
				</template>
				<template>
					<div
						class="container-fluid overflow-overlay pb-2 px-0 m-0"
						id="execucaoOnline"
						:class="isMobile ? 'tamanho-card-mobile' : 'tamanho-card'"
					>
						<div class="row shadow-sm shadow-hover">
							<div class="col-12 pb-2">
								<span :class="isMobile ? 'h5 text-muted' : 'h1'"
									>Conferência de Itens (Recebimento:
									<strong>{{ idRecebimento }})</strong>
								</span>
							</div>
						</div>
						<div class="row mt-2 mx-0 align-items-end">
							<div class="col-10 p-0" v-if="controlaLocalizacaoWMS">
								<label class="mb-0"> Localização </label>
								<div class="input-group">
									<input
										placeholder="Código de Barras da Localização"
										class="form-control form-control-sm"
										type="text"
										v-model="siglaLocalizacao"
									/>
									<div class="input-group-append">
										<button
											v-if="siglaLocalizacao && siglaLocalizacao.length"
											class="btn-icon-xs btn-clear"
											@click="siglaLocalizacao = null"
											title="Limpar campo Localização"
										>
											<i class="icon icon-lx-close"></i>
										</button>
									</div>
								</div>
							</div>
							<div class="col-2 pr-0 pl-2" v-if="isMobile && controlaLocalizacaoWMS">
								<button class="btn btn-secondary form-control form-control-sm" @click="abrirLeitorCodigoBarras">
									<i class="fa fa-barcode" aria-hidden="true"></i>
								</button>
							</div>
						</div>
						<div class="row mt-2 mx-0 align-items-end">
							<div class="col-2 pr-2 pl-0">
								<label class="mb-0"> Qtde</label>
								<InputNumber
									v-model="produto.Quantidade"
									:disabled="requerLocalizacao"
									:precisao="casasDecimaisQtdeProduto"
									class="form-control form-control-sm"
								/>
							</div>
							<div class="col-8 p-0">
								<label class="mb-0"> Produto </label>
								<div class="input-group">
									<input
										:disabled="requerLocalizacao"
										placeholder="Código de Barras"
										@keyup.enter="buscarItem(produto.CodeBar)"
										class="form-control form-control-sm"
										type="number"
										v-model="produto.CodeBar"
									/>
									<div class="input-group-append">
										<button
											v-if="produto.CodeBar && produto.CodeBar.length"
											class="btn-icon-xs btn-clear"
											@click="produto.CodeBar = null"
											title="Limpar campo Produto"
										>
											<i class="icon icon-lx-close"></i>
										</button>
									</div>
									<div class="input-group-append">
										<button
											:disabled="requerLocalizacao"
											type="button"
											class="btn btn-sm btn-primary"
											@click="buscarItem(produto.CodeBar)"
										>
											<i class="fas fa-plus"></i>
										</button>
									</div>
								</div>
							</div>
							<div class="col-2 pr-0 pl-2" v-if="isMobile">
								<button
									:disabled="requerLocalizacao"
									class="btn btn-secondary form-control form-control-sm"
									@click="abrirLeitorCodigoBarras"
								>
									<i class="fa fa-barcode" aria-hidden="true"></i>
								</button>
							</div>
						</div>
						<div class="row mt-2 mx-0 align-items-end">
							<p>
								TOTAL DE PRODUTOS: <b>{{ formatarValorTotal(totalizador().QuantidadeItens) }}</b>
							</p>
						</div>
						<div class="row flex-grow-1 mt-3 m-0 p-0">
							<div class="col-12 m-0 p-0">
								<div class="table-responsive mb-3" style="height: calc(100vh - 270px); overflow-y: auto">
									<table class="table table-sm table-striped table-hover" cellspacing="1">
										<thead>
											<tr>
												<th class="text-left text-nowrap">Produto</th>
												<th v-if="!isMobile" class="text-left text-nowrap">Código de Barras</th>
												<th class="text-center text-nowrap">Qtde</th>
												<th v-if="controlaLocalizacaoWMS && !isMobile" class="text-left text-nowrap">
													Localização
												</th>
												<th class="text-left text-nowrap">Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(produtoConferido, idx) in listaItensConferidos" :key="idx">
												<td class="text-left fz-80" v-if="isMobile">
													{{ produtoConferido.CodigoProduto }}
													<span class="text-muted">
														{{ produtoConferido.CodigoBarras }}<br />{{
															produtoConferido.Descricao
														}}
														({{ produtoConferido.Referencia }})<br />
													</span>
													<div v-if="controlaLocalizacaoWMS">
														<strong>Localização: </strong>
														<span class="text-muted">
															{{ produtoConferido.SiglaLocalizacaoWMS }}
														</span>
													</div>
												</td>
												<td class="text-left align-middle" v-if="!isMobile">
													{{ produtoConferido.CodigoProduto }}
													<span class="text-muted"
														>- {{ produtoConferido.Descricao }} ({{ produtoConferido.Referencia }})
													</span>
												</td>
												<td class="text-left align-middle" v-if="!isMobile">
													{{ produtoConferido.CodigoBarras }}
												</td>
												<td class="text-center align-middle">
													{{ formatarValorQtde(produtoConferido.QtdeProduto) }}
												</td>
												<td class="text-left align-middle" v-if="controlaLocalizacaoWMS && !isMobile">
													{{ produtoConferido.SiglaLocalizacaoWMS }}
												</td>
												<td colspan="1" class="text-left text-nowrap align-middle">
													<button
														class="btn btn-danger mr-1"
														@click="onExcluirProduto(produtoConferido)"
														v-tooltip="apresentarTooltip('Excluir')"
													>
														<i class="fas fa-trash-alt"></i>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<LeitorCodigoBarras v-model="leitorCodigoBarras.retorno" :ativo="leitorCodigoBarras.ativo" />
					<audio ref="beep" src="./../../common/audio/beep.mp3"></audio>
					<audio ref="beepErro" src="./../../common/audio/beepErro.mp3"></audio>
				</template>
			</div>
			<div class="shadow-sm card-panel d-flex align-items-center detalhes-footer fixed-bottom">
				<div class="d-flex justify-content-between w-100">
					<div>
						<span class="text-uppercase small" style="cursor: default">
							{{ totalizador().Inseridos }} itens inseridos |
							{{ formatarValorTotal(totalizador().QuantidadeItens) }} unidades
						</span>
					</div>
					<button
						id="lmxta-dadosadicionais-btn-salvar"
						type="button"
						class="btn btn-primary rounded"
						tabindex="8"
						@click="finalizarConferenciaOnline()"
					>
						<span class="text-center">Finalizar</span>
					</button>
				</div>
			</div>
		</template>
		<div
			v-else-if="!recebimentoExiste && !isLoading"
			class="h-100 w-100 lmx-tela-relatorio-div-nenhum-registro d-flex justify-content-center align-items-center"
		>
			<div class="text-center shadow shadow-sm p-4 mx-4">
				<i class="fa fa-exclamation-triangle fa-3x fa-fw text-primary m-2"></i>
				<span class="text-justify text-primary mt-0 mb-0">
					<h2 class="text-center">{{ msgRetornoRecebimento }}</h2>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import swal from '@/common/alerts';
	import loading from '@/common/loading';
	import LeitorCodigoBarras from '@/components/shared/LeitorCodigoBarras.vue';
	import InputNumber from '@/components/shared/input/InputNumber.vue';
	import { mapGetters } from 'vuex';
	import VTooltip from 'v-tooltip';
	import Vue from 'vue';

	Vue.use(VTooltip);

	export default {
		components: {
			LeitorCodigoBarras,
			InputNumber,
		},
		data() {
			return {
				errosValidacao: {},
				recebimentoExiste: false,
				idRecebimento: null,
				siglaLocalizacao: '',
				msgRetornoRecebimento: '',
				conferenciaOnlineFinalizada: false,
				produto: {
					NomeProduto: '',
					Quantidade: 1,
					CodeBar: '',
					CodigoProduto: null,
				},
				ativo: false,
				leitorCodigoBarras: {
					ativo: false,
					retorno: null,
				},
			};
		},
		created() {
			var self = this;
			self.idRecebimento = self.$route.query.idRecebimento;
		},
		beforeMount() {
			this.recebimentoExiste = false;
			this.$store.dispatch('recebimento/atualizarListaItensConferidos', []);
			this.obterDadosRecebimento();
		},
		watch: {
			'leitorCodigoBarras.retorno': function (result) {
				if (this.leitorCodigoBarras.ativo) {
					this.leitorCodigoBarras.ativo = false;
				}

				if (result && result.codigoBarras) {
					this.produto.CodeBar = result.codigoBarras;
					this.buscarItem(result.codigoBarras, true);
				}
			},
		},
		computed: {
			...mapGetters({
				listaItensConferidos: 'recebimento/listaItensConferidos',
			}),
			isLoading() {
				return this.$store.state.uiState.isLoading;
			},
			isMobile() {
				if (navigator.userAgent.indexOf('Mobile') > 0) {
					return true;
				} else {
					return false;
				}
			},
			requerLocalizacao() {
				return this.controlaLocalizacaoWMS && !this.siglaLocalizacao;
			},
			casasDecimaisQtdeProduto() {
				return 0;
			},
			bootstrapConflict() {
				return {
					template:
						'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
					arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
					innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
				};
			},
		},
		methods: {
			totalizador() {
				let totalizador = {
					Inseridos: 0,
					QuantidadeItens: 0,
				};
				var lista = this.listaItensConferidos;

				lista?.forEach((item) => {
					totalizador.Inseridos++;
					totalizador.QuantidadeItens += item.QtdeConferida;
				});

				return totalizador;
			},
			apresentarTooltip(mensagem) {
				return { content: mensagem, ...this.bootstrapConflict };
			},
			async buscarItem(codebar, ehLeituraCodigoBarras) {
				var self = this;
				self.leitorCodigoBarras.retorno = null;

				if (this.requerLocalizacao) {
					swal.exibirMensagem('Favor informar a localização do produto');
					return;
				}
				if (codebar == '') {
					swal.exibirMensagem('Informe um código de barras');
					return;
				}
				var quantidade = self.produto.Quantidade || 1;
				quantidade = parseFloat(quantidade);

				codebar = codebar.trim();
				let commandItens = {
					IdRecebimentoMercadoria: self.idRecebimento,
					CodigoBarras: codebar,
					Quantidade: quantidade,
					SiglaLocalizacaoWMS: self.siglaLocalizacao.trim(),
				};
				loading.exibir();
				self.$store
					.dispatch('recebimento/conferirItem', commandItens)
					.then((response) => {
						self.$refs.beep.play();
						self.$store.dispatch('recebimento/atualizarListaItensConferidos', response.data);
						self.totalizador();
						self.limparCampos();
						if (ehLeituraCodigoBarras) self.abrirLeitorCodigoBarras();
					})
					.catch((erro) => {
						self.$refs.beepErro.play();
						let mensagemErro = '';
						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			onExcluirProduto(produto) {
				let descProduto = `${produto.CodigoProduto} (${produto.CodigoBarras}) - ${produto.Descricao}`;
				swal.exibirPergunta(
					'Excluir Item',
					`Deseja excluir o item:<br> <b>${descProduto}</b> <br> da lista de conferência?`,
					async (response) => {
						if (response) {
							this.excluirProduto(produto);
						}
					},
				);
			},
			excluirProduto(produto) {
				var self = this;
				let command = {
					IdRecebimentoMercadoria: self.idRecebimento,
					CodigoProduto: produto.CodigoProduto,
				};
				loading.exibir();
				self.$store
					.dispatch('recebimento/excluirItemRecebimentoMercadorias', command)
					.then(() => {
						let produtos = self.listaItensConferidos?.filter((item) => {
							return !(
								item.CodigoProduto == produto.CodigoProduto &&
								item.IdProdutoLocalizacaoDeposito == produto.IdProdutoLocalizacaoDeposito
							);
						});

						if (produtos) {
							self.$store.dispatch('recebimento/atualizarListaItensConferidos', produtos);
							swal.exibirToastSucesso('Produto excluído!');
						}
					})
					.catch((erro) => {
						self.$refs.beepErro.play();
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			limparCampos() {
				var self = this;
				self.produto.CodeBar = '';
				self.produto.Quantidade = 1;
			},
			async finalizarConferenciaOnline() {
				var self = this;
				if (!self.listaItensConferidos?.length) {
					swal.exibirMensagemAlerta('Não é permitido finalizar a conferência sem itens.');
					return;
				}
				loading.exibir();

				self.$store
					.dispatch('recebimento/finalizarConferenciaOnline', self.idRecebimento)
					.then(() => {
						swal.exibirMensagemSucesso('Conferência finalizada com sucesso', () => {
							self.conferenciaOnlineFinalizada = true;
							self.redirecionarPagina();
						});
						return Promise.resolve();
					})
					.catch(function (erro) {
						if (typeof erro == 'string') {
							swal.exibirMensagemErro(erro);
						} else {
							swal.exibirMensagemErro('Ocorreu um problema');
						}
						return Promise.reject(erro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			obterDadosRecebimento() {
				var self = this;
				loading.exibir();
				return self.$store
					.dispatch('recebimento/obterDadosRecebimento', self.idRecebimento)
					.then((response) => {
						self.recebimentoExiste = true;
						self.controlaLocalizacaoWMS = response.data?.ControlaLocalizacaoWMS;
						self.conferenciaOnlineFinalizada = response.data?.ConferenciaOnlineFinalizada;
						if (self.conferenciaOnlineFinalizada) self.redirecionarPagina();
						return Promise.resolve();
					})
					.catch(function (erro) {
						let mensagemErro = '';

						if (erro.response) {
							if (erro.response.status == 400) mensagemErro = erro.data;
							else mensagemErro = erro.message;
						} else mensagemErro = erro;

						self.msgRetornoRecebimento = mensagemErro;
						swal.exibirMensagemErro(mensagemErro);
						return Promise.reject(mensagemErro);
					})
					.finally(function () {
						loading.ocultar();
					});
			},
			redirecionarPagina() {
				this.$router.push({
					name: 'ConferenciaOnlineRecebimentoFinalizada',
					params: { idRecebimento: this.idRecebimento },
				});
			},
			abrirLeitorCodigoBarras() {
				this.leitorCodigoBarras.ativo = true;
			},
			formatarValorTotal(value) {
				var valor = parseFloat(value).toFixed(this.casasDecimaisQtdeProduto);
				return this.formatarValorQtde(valor);
			},
			formatarValorQtde(value) {
				var self = this;
				return new Intl.NumberFormat('pt-BR', {
					maximumFractionDigits: self.casasDecimaisQtdeProduto,
					roundingMode: 'trunc',
				}).format(parseFloat(value));
			},
		},
	};
</script>

<style scoped>
	.tamanho-card-mobile {
		height: calc(100% - 100px);
	}

	.tamanho-card {
		height: 100%;
	}

	.btn-clear {
		color: #ee9ca4;
		background-color: #fff;
		border-color: #f3828e;
	}

	tr > td:nth-child(2) {
		width: 15rem;
	}
</style>
